.container {
  background: #ffffff;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header {
  font-size: 18px;
  margin: 0;
}
.subheader {
  color: rgba(0, 0, 0, 0.45);
}
