.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.table {
  width: 50%;
}
.table + .table {
  margin-left: 12px;
}
.tableRow {
  cursor: pointer;
}
